import React, { useEffect, useState } from "react"
import { Layout, SEO } from '../../components/structure';
import { PartnerGrid, Hero, Content } from "../../components/blocks"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../styles";


import english from "../../../content/translations/en-CA/partners.json"
import french from "../../../content/translations/fr-CA/partners.json"

const PartnerFilterSelect = styled.div`
  
  ${ font.imports.sherika }

  background-color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  width: 100%;
  max-width: 800px;
  
  @media only screen and ${mq.maxMd} {
    flex-direction: column;
    width: 150px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .select-title {
    background-color: ${brand.colors.pg_blue};
    font-family: ${font.family.sherika};
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    color: #fff;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: 10px 20px;
    flex-basis: 150px;
    display: flex;
    align-items: center;

    @media only screen and ${mq.maxMd} {
      flex-basis: 50px;
      border-radius: 25px;
    }

    .gatsby-image-wrapper {
      padding: 0;
      flex-basis: 30px;
    }

    .icon-con {
      display: inline-block;
      width: 30px;
      height: 30px;
    }

  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
    padding: 0 30px;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px;
    }
    span {
      text-align: center;
      color: ${brand.colors.pg_primary_dark};
      font-family: ${font.family.sherika};
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;

      .mobile-break {
        display: none;

        @media only screen and ${mq.maxMd} {
          display: block;
        }
      }

        &:after {
          content: '';
          display: inline-block;
          visibility: hidden;
          position: relative;
          bottom: -16px;
          left: -50%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 5px 6px 5px;
          border-color: transparent transparent ${brand.colors.pg_blue} transparent;

          @media only screen and ${mq.maxMd} {
            transform: rotate(90deg);
            bottom: 2px;
            left: -100%;
          }
      }

      &:hover {
        color: ${brand.colors.pg_blue};
      }

      &:last-of-type {
        &:after {
          @media only screen and ${mq.maxMd} {
          top: -34px;
          }
        }
      }
    }

    .visible-marker {
      color: ${brand.colors.pg_blue} !important;
      font-weight: 800 !important;
      &:after {
        visibility: visible !important;
      }
    }
  }
`;

const PartnerList = styled.div`
  width: 95%;
  max-width: 1400px;
  margin: auto;
`

const PartnerFilterControl = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and ${mq.maxMd} {
    flex-direction: row;
    gap: 5px;
  }

  @media only screen and (max-width: 884px) {
    justify-content: center;
  }
`;

const LoyaltyPartnersPage = () => {

  let lang = english;

  const [regionFilter, setRegionFilter] = useState("all_regions");
  const [industryFilter, setIndustryFilter] = useState("all_industries");


const applyRegionFilter = ((e) => {

  let eventID = e.target.id;
  setRegionFilter(eventID);
  console.log("New Region ID is: " + regionFilter);
  console.log("Selected is: " + regionFilter + " and " + industryFilter);
  let options = document.getElementsByClassName('region-option');
  for (let x = 0; x < options.length; x++) {
    options[x].classList.remove("visible-marker");
  }
  document.querySelector("#" + eventID).classList.add("visible-marker");

});

const applyIndustryFilter = ((e) => {

  let eventID = e.target.id;
  setIndustryFilter(eventID);

  let options = document.getElementsByClassName('industry-option');
  for (let x = 0; x < options.length; x++) {
    options[x].classList.remove("visible-marker");
  }
  document.querySelector("#" + eventID).classList.add("visible-marker");

});

  const data = useStaticQuery(graphql`
    query {
      partnerHeroBackground: file(base: {eq: "Plusgrade-Hero-Banner-Our-Partners.jpg"}){
        ...FullHeroBackground
      }
      barsIcon: file(base: {eq: "bars-white.png"}){
        childImageSharp{
          fixed(width: 40, height: 30, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return(
  <Layout>
      <SEO title={ 'Partenaires | Plusgrade' } 
          description={'Plusgrade travaille en partenariat avec plus de 200 compagnies aériennes, hôtelières, de croisière, de rail et de services financiers parmi les plus importantes au monde.'}
      />
      <Hero
        title={"Nos partenaires"}
        image= {data['partnerHeroBackground']}
        fullBG
      />
      <Content>
        <PartnerList>
          <div style={{width: "100%", margin: "50px auto"}}>
            <h4 style={{fontSize: "24px"}}>Plusgrade travaille en partenariat avec plus de 200 compagnies aériennes, hôtelières, de croisière, de rail et de services financiers parmi les plus importantes au monde.</h4>
          </div>

          <PartnerFilterControl>
          <PartnerFilterSelect>
            <span className="select-title"><GatsbyImage fixed={data['barsIcon'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}}/>Industries</span>
            <div className="industry-options">
              <span className="industry-option visible-marker" id="all_industries"  onClick={e => applyIndustryFilter(e)}>Tous les pays</span>
              <span className="industry-option" id="air"  onClick={e => applyIndustryFilter(e)}>Air</span>
              <span className="industry-option" id="cruise"  onClick={e => applyIndustryFilter(e)}>Croisière</span>
              <span className="industry-option" id="rail"  onClick={e => applyIndustryFilter(e)}>Rail</span>
              <span className="industry-option" id="hospitality"  onClick={e => applyIndustryFilter(e)}>Hôtellerie</span>
              <span className="industry-option" id="finance"  onClick={e => applyIndustryFilter(e)}>Finances</span>
            </div>

          </PartnerFilterSelect>
          
          <PartnerFilterSelect>
            <span className="select-title"><GatsbyImage fixed={data['barsIcon'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}}/>Région</span>
            <div className="region-options">
              <span className="region-option visible-marker" id="all_regions" onClick={e => applyRegionFilter(e)}>Tous les pays</span>
              <span className="region-option" id="americas" onClick={e => applyRegionFilter(e)}>Amériques</span>
              <span className="region-option" id="apac" onClick={e => applyRegionFilter(e)}>Asie-Pacifique</span>
              <span className="region-option" id="europe" onClick={e => applyRegionFilter(e)}>Europe</span>
              <span className="region-option" id="mea" onClick={e => applyRegionFilter(e)}>Moyen-Orient <br className="mobile-break" style={{height: '0'}}></br>et Afrique</span>
            </div>
          </PartnerFilterSelect>
          </PartnerFilterControl>
          <PartnerGrid selected={(regionFilter + "_" + industryFilter)} />
        </PartnerList>
      </Content>
      
    </Layout>
  )
  
  };

export default LoyaltyPartnersPage;
